<template>
  <div id="ponudaWrapper">
    <div id="ponudaHeader">
      <div id="podkatWrapp">
        <scrollactive
          class="my-nav"
          :highlightFirstItem="true"
          :offset="380"
          :duration="800"
          bezier-easing-value=".5,0,.35,1"
          :modifyUrl="false"
        >
          <span>PODKATEGORIJE:</span>
          <a
            :href="'#' + item.pod_url"
            class="scrollactive-item"
            v-for="(item, index) in podkategorije"
            :key="index"
          >
            {{ podkategorije[index].pod_naziv }}
          </a>
        </scrollactive>
      </div>
      <div id="sortWrapp">
        Raspon cijena
        <vue-slider
          class="prSl"
          :tooltip-formatter="'{value}€'"
          :interval="100"
          v-model="priceRange"
          :marks="true"
          :hide-label="true"
          :process-style="{ backgroundColor: '#71b100' }"
          :min="0"
          :max="2000"
          width="15vw"
        >
          <template v-slot:step="{}">
            <div style="display: none"></div>
          </template>
          <template v-slot:dot="{ value }">
            <div class="rangeDot">{{ value == 2000 ? "2000+" : value }}</div>
          </template></vue-slider
        >
      </div>
    </div>
    <div id="ponudaBody">
      <div id="podkatWrapp">
        <div class="my-nav">
          <span>PODKATEGORIJE:</span>
          <a
            :href="'#' + item.pod_url"
            class="scrollactive-item"
            v-for="(item, index) in podkategorije"
            :key="index"
          >
            {{ podkategorije[index].pod_naziv }}
          </a>
        </div>
      </div>
      <div
        class="itemWrapper"
        v-for="(podItem, index) in podkategorije"
        :key="index"
      >
        <div :id="podItem.pod_url" class="separators"></div>
        <div
          class="item"
          v-for="(item, index) in podItem.artikli"
          :key="index"
          v-bind:class="'koko' + item.pod_ipr"
        >
          <router-link
            :to="
              'novo/' +
              item.kat_url +
              '/' +
              item.art_id +
              '/' +
              item.pod_url +
              '-' +
              item.art_naziv.toLowerCase().replace(' ', '-')
            "
          >
            <img
              :src="'/images/namjestaj/' + item.art_id + '/thumb.jpg'"
              :alt="item.pod_naziv + item.art_naziv"
            />
            <div v-if="item.art_sastav == 0 || item.art_sastav == 1">
              <div class="naziv">
                {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                {{ item.art_boja }}
              </div>
              <div class="popCijena">
                <div v-if="item.art_gornja != 0">
                  {{ item.art_gornja }}<span>€</span>
                </div>
                <div v-if="item.art_gornja2 != 0">
                  {{ item.art_gornja2 }}<span>€</span>
                </div>
                <div v-if="item.art_srednja != 0">
                  {{ item.art_srednja }}<span>€</span>
                </div>
              </div>
              <div v-if="item.art_donja != 0" class="cijena">
                {{ item.art_donja }}<span>€</span>
              </div>
              <div class="popustWrapper">
                <div class="popust popdod" v-if="item.art_gornja != 0">
                  <div>
                    {{ calcPopustB(item.art_donja, item.art_srednja)
                    }}<span>%</span>
                  </div>
                </div>
                <div class="popust popdod2" v-if="item.art_gornja2 != 0">
                  <div>
                    {{ calcPopustB(item.art_donja, item.art_srednja)
                    }}<span>%</span>
                  </div>
                </div>
                <div
                  class="popust"
                  v-bind:class="
                    item.art_rasprodaja == 1
                      ? 'popeksponat'
                      : item.art_rasprodaja == 2
                      ? 'popfinalna'
                      : 'popsniz'
                  "
                  v-if="item.art_srednja != 0"
                >
                  <div>
                    {{
                      calcPopustA(
                        item.art_donja,
                        item.art_srednja,
                        item.art_gornja,
                        item.art_gornja2
                      )
                    }}<span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="item.art_sastav == 2">
              <div v-if="elemLoaded">
                <div
                  class="naziv"
                  v-if="item.art_naziv == ''"
                  v-html="sastavNaziv(item.art_artikli)"
                >
                  <span v-if="item.art_boja">{{ item.art_boja }}</span>
                </div>
                <div class="naziv" v-else>
                  {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                  {{ item.art_boja }}
                </div>
                <div class="popCijena">
                  <div v-if="item.art_gornja != 0">
                    {{ item.art_gornja }}<span>€</span>
                  </div>
                  <div v-if="item.art_gornja2 != 0">
                    {{ item.art_gornja2 }}<span>€</span>
                  </div>
                  <div v-if="item.art_srednja != 0">
                    {{ item.art_srednja }}<span>€</span>
                  </div>
                </div>
                <div v-if="item.art_donja != 0" class="cijena">
                  {{ item.art_donja }}<span>€</span>
                </div>
              </div>
              <div class="popustWrapper">
                <div class="popust popdod" v-if="item.art_gornja != 0">
                  <div>
                    {{ calcPopustB(item.art_donja, item.art_srednja)
                    }}<span>%</span>
                  </div>
                </div>
                <div class="popust popdod2" v-if="item.art_gornja2 != 0">
                  <div>
                    {{ calcPopustB(item.art_donja, item.art_srednja)
                    }}<span>%</span>
                  </div>
                </div>
                <div class="popust popsniz" v-if="item.art_srednja != 0">
                  <div>
                    {{
                      calcPopustA(
                        item.art_donja,
                        item.art_srednja,
                        item.art_gornja,
                        item.art_gornja2
                      )
                    }}<span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="item.art_sastav == 3">
              <div v-if="elemLoaded">
                <div class="naziv">
                  {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                  {{ item.art_boja }}
                </div>
                <div class="popCijena">
                  <div v-if="item.art_gornja != 0">
                    {{ item.art_gornja }}<span>€</span>
                  </div>
                  <div v-if="item.art_gornja2 != 0">
                    {{ item.art_gornja2 }}<span>€</span>
                  </div>
                  <div v-if="item.art_srednja != 0">
                    {{ item.art_srednja }}<span>€</span>
                  </div>
                </div>
                <div v-if="item.art_donja != 0" class="cijena">
                  {{ item.art_donja }}<span>€</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  data() {
    return {
      priceRange: [0, 2000],
      marks: [0, 10, 30, 60, 100],
      sortDirection: false,
      itemList: [],
      tempList: [],
      podList: {},
      elemList: {},
      tmpPod: "",
      intr: "",
      elemLoaded: false,
      podkategorije: [],
      kat: "novo"
    };
  },
  components: {
    VueSlider
  },
  created() {
    if (this.sList == null) {
      this.loadItems();
    } else {
      this.podkategorije = this.sList;
      this.elemList = this.sElems;
      this.elemLoaded = true;
    }
    window.scrollTo(0, 0);
  },
  methods: {
    methodToRunOnSelect(payload) {
      this.object = payload;
    },
    calcPopustA(donja, srednja, gornja, gornja2) {
      var a = 0.0;
      if (gornja != 0) {
        a = ((gornja - srednja) / gornja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else if (gornja2 != 0) {
        a = ((gornja2 - srednja) / gornja2) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else {
        a = ((srednja - donja) / srednja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      }
    },
    calcPopustB(donja, srednja) {
      var a = ((srednja - donja) / srednja) * 100;
      if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
      return Math.floor(a);
    },
    sastavNaziv(item) {
      var a = "";
      if (item.length > 0)
        JSON.parse(item).map(val => {
          a =
            a +
            (val.kolicina > 1 ? val.kolicina + "x " : "") +
            this.elemList[val.sifra].pod_naziv +
            " <span>" +
            this.elemList[val.sifra].art_naziv +
            "</span> + ";
        });
      return a.substring(0, a.length - 3);
    },
    getUnique(a) {
      if (this.podList[a.pod_naziv] == null) {
        this.podList[a.pod_naziv] = {};
        this.podList[a.pod_naziv].pod_url = a.pod_url;
        this.podList[a.pod_naziv].artikli = [a];
        this.podList[a.pod_naziv].pod_sort = a.pod_sort;
      } else {
        this.podList[a.pod_naziv].artikli.push(a);
      }
      JSON.parse(a.art_artikli).map(item => (this.elemList[item.sifra] = {}));
      return a;
    },
    loadElems() {
      if (Object.keys(this.elemList).length > 0)
        fetch(
          "/kaoitemlist.php?elems=" + Object.keys(this.elemList).join(",")
        ).then(response => {
          response.json().then(data => {
            data.map(item => (this.elemList[item.art_id] = item));
            this.$store.dispatch("saveElems", this.elemList);
            this.elemLoaded = true;
          });
        });
    },
    loadItems() {
      this.itemList = [];
      fetch("/kaoitemlist.php?novo=1").then(response => {
        response.json().then(data => {
          this.tempList = data;
          while (this.tempList.length != 0) {
            this.itemList.push(this.getUnique(this.tempList.shift()));
          }
          Object.keys(this.podList).map(item =>
            this.podkategorije.push({
              pod_naziv: item,
              pod_url: this.podList[item].pod_url,
              pod_sort: this.podList[item].pod_sort,
              artikli: this.podList[item].artikli
            })
          );
          this.podkategorije.sort((a, b) =>
            a.pod_sort < b.pod_sort ? -1 : Number(a.pod_sort > b.pod_sort)
          );
          this.$store.dispatch("saveLocation", this.kat);
          this.$store.dispatch("saveList", this.podkategorije);
          this.loadElems();
        });
      });
    }
  },
  computed: {
    sList: function() {
      return this.$store.getters.sList[this.kat];
    },
    sElems: function() {
      return this.$store.getters.sElems[this.kat];
    }
  }
};
</script>
<style lang="scss">
@import "./common/ponudadesk.scss";
@import "./common/ponudamob.scss";
</style>
