<template>
  <div id="item" v-cloak>
    <div id="itemHolder">
      <div id="ileft">
        <div id="picHolder">
          <zoom-on-hover
            :altText="itemObject.pod_naziv + ' ' + itemObject.art_naziv"
            :img-normal="
              '//namos.co.me/images/namjestaj/' +
              itemObject.art_id +
              '/' +
              pixIndex +
              '.jpg'
            "
            :img-zoom="
              '//namos.co.me/images/namjestaj/' +
              itemObject.art_id +
              '/' +
              pixIndex +
              'b.jpg'
            "
            :scale="1.5"
            :disabled="zooms"
          ></zoom-on-hover>
        </div>
        <div id="restPic">
          <div
            class="restEl"
            v-for="index in Number(itemObject.art_brslika) || 0"
            :key="'a' + index"
          >
            <img
              :alt="itemObject.pod_naziv + ' ' + itemObject.art_naziv"
              :src="
                '/images/namjestaj/' +
                itemObject.art_id +
                '/' +
                (index - 1) +
                '.jpg'
              "
              @click="changeImage(index - 1)"
            />
          </div>
        </div>
      </div>
      <div id="iRight">
        <div id="katt">
          <h2>
            {{ itemObject.kat_naziv
            }}<span v-if="itemObject.pod_naziv != null"> - </span
            >{{ itemObject.pod_naziv }}
          </h2>
        </div>
        <div id="title" v-if="itemObject.art_naziv != ''">
          MODEL:
          <h1>{{ itemObject.art_naziv }}</h1>
          <br />BOJA: {{ itemObject.art_boja }}
        </div>
        <div class="popCijena">
          <div v-if="itemObject.art_gornja != 0">
            {{ itemObject.art_gornja }}<span>€</span>
          </div>
          <div v-if="itemObject.art_srednja != 0">
            {{ itemObject.art_srednja }}<span>€</span>
          </div>
        </div>
        <div v-if="itemObject.art_donja != 0" class="cijena">
          {{ itemObject.art_donja }}<span>€</span>
        </div>
        <div
          v-if="
            itemObject.art_sastav == 2 &&
            elemLoaded &&
            itemObject.art_donja != 0
          "
        >
          (
          {{
            JSON.parse(itemObject.art_artikli)
              .map(function (e) {
                if (e.kolicina == 1) return elemList[e.sifra].pod_naziv;
                return e.kolicina + "x " + elemList[e.sifra].pod_naziv;
              })
              .join(" + ")
          }}
          )
        </div>
        <div class="popustWrapper">
          <div class="popust popdod" v-if="itemObject.art_gornja != 0">
            <div>
              {{ calcPopustB(itemObject.art_donja, itemObject.art_srednja)
              }}<span>%</span>
            </div>
          </div>
          <div class="popust popdod2" v-if="itemObject.art_gornja2 != 0">
            <div>
              {{ calcPopustB(itemObject.art_donja, itemObject.art_srednja)
              }}<span>%</span>
            </div>
          </div>
          <div
            class="popust"
            v-bind:class="
              itemObject.art_rasprodaja == 1
                ? 'popeksponat'
                : itemObject.art_rasprodaja == 2
                ? 'popfinalna'
                : 'popsniz'
            "
            v-if="itemObject.art_srednja != 0"
          >
            <div>
              {{
                calcPopustA(
                  itemObject.art_donja,
                  itemObject.art_srednja,
                  itemObject.art_gornja,
                  itemObject.art_gornja2
                )
              }}<span>%</span>
            </div>
          </div>
        </div>
        <br />
        <div
          id="dobavljac"
          v-if="itemObject.dob_naziv != 'aa-nema' && itemObject.dob_prikaz == 1"
        >
          <img :src="'/images/dobavljaci/' + itemObject.dob_url + '.png'" />
        </div>
        <div id="tekst" v-html="itemObject.art_tekst"></div>
        <div
          id="spec"
          v-if="
            itemObject.art_spec != '[]' &&
            JSON.parse(itemObject.art_spec).length > 0
          "
        >
          <div id="specNaslov">Specifikacija:</div>
          <div id="specList">
            <div v-for="aha in JSON.parse(itemObject.art_spec)" :key="aha.n">
              ✓ {{ aha.n }}
            </div>
          </div>
        </div>
        <div
          id="dim"
          v-if="
            itemObject.art_sirina != '' ||
            itemObject.art_visina != '' ||
            itemObject.art_dubina != ''
          "
        >
          <div id="dimNaslov">Dimenzije:</div>
          <div id="dimList">
            <div v-if="itemObject.art_sirina != ''">
              Širina: {{ itemObject.art_sirina }}<br />
            </div>
            <div v-if="itemObject.art_visina != ''">
              Visina: {{ itemObject.art_visina }}
            </div>
            <div v-if="itemObject.art_dubina != ''">
              Dubina: {{ itemObject.art_dubina }}<br />
            </div>
          </div>
        </div>
        <div v-if="itemObject.art_sastav == 2 && elemLoaded">
          <div
            class="elemHolder"
            v-for="(item, index) in Object.keys(elemList)"
            :key="index"
          >
            <div class="elCijena">
              <div class="elemNaziv">
                {{ elemList[item].pod_naziv }} {{ elemList[item].art_naziv }}
              </div>
              <div class="popCijena">
                <div v-if="elemList[item].art_gornja != 0">
                  {{ elemList[item].art_gornja }}<span>€</span>
                </div>
                <div v-if="elemList[item].art_gornja2 != 0">
                  {{ elemList[item].art_gornja2 }}<span>€</span>
                </div>
                <div v-if="elemList[item].art_srednja != 0">
                  {{ elemList[item].art_srednja }}<span>€</span>
                </div>
              </div>
              <div v-if="elemList[item].art_donja != 0" class="cijena">
                {{ elemList[item].art_donja }}<span>€</span>
              </div>
              <div
                v-if="
                  elemList[item].art_sastav == 2 &&
                  elemLoaded &&
                  elemList[item].art_donja != 0
                "
              >
                (
                {{
                  JSON.parse(elemList[item].art_artikli)
                    .map(function (e) {
                      if (e.kolicina == 1) return elemList[e.sifra].pod_naziv;
                      return e.kolicina + "x " + elemList[e.sifra].pod_naziv;
                    })
                    .join(" + ")
                }}
                )
              </div>
              <div class="popustWrapper">
                <div
                  class="popust popdod"
                  v-if="elemList[item].art_gornja != 0"
                >
                  <div>
                    {{
                      calcPopustB(
                        elemList[item].art_donja,
                        elemList[item].art_srednja
                      )
                    }}<span>%</span>
                  </div>
                </div>
                <div
                  class="popust popdod2"
                  v-if="elemList[item].art_gornja2 != 0"
                >
                  <div>
                    {{
                      calcPopustB(
                        elemList[item].art_donja,
                        elemList[item].art_srednja
                      )
                    }}<span>%</span>
                  </div>
                </div>
                <div
                  class="popust"
                  v-bind:class="
                    elemList[item].art_rasprodaja == 1
                      ? 'popeksponat'
                      : elemList[item].art_rasprodaja == 2
                      ? 'popfinalna'
                      : 'popsniz'
                  "
                  v-if="elemList[item].art_srednja != 0"
                >
                  <div>
                    {{
                      calcPopustA(
                        elemList[item].art_donja,
                        elemList[item].art_srednja,
                        elemList[item].art_gornja,
                        elemList[item].art_gornja2
                      )
                    }}<span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="spec"
              class="nar"
              v-if="
                elemList[item].art_spec != '[]' &&
                JSON.parse(elemList[item].art_spec).length > 0
              "
            >
              <div id="specNaslov">Specifikacija:</div>
              <div id="specList">
                <div
                  v-for="aha in JSON.parse(elemList[item].art_spec)"
                  :key="aha.n"
                >
                  ✓ {{ aha.n }}
                </div>
              </div>
            </div>
            <div id="dim">
              <div id="dimNaslov">Dimenzije:</div>
              <div id="dimList">
                <div v-if="elemList[item].art_sirina != ''">
                  Širina: {{ elemList[item].art_sirina }}<br />
                </div>
                <div v-if="elemList[item].art_visina != ''">
                  Visina: {{ elemList[item].art_visina }}
                </div>
                <div v-if="elemList[item].art_dubina != ''">
                  Dubina: {{ elemList[item].art_dubina }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  data() {
    return {
      itemObject: {},
      pixIndex: 0,
      razlika: 4,
      elemList: {},
      elemLoaded: false,
      zooms: true
    };
  },
  metaInfo() {
    return {
      title: this.sTitle,
      meta: [
        { name: "description", content: this.sDesc },
        { property: "og:type", content: "website" },
        { property: "og:title", content: this.sTitle },
        { property: "og:image", content: this.sImage },
        {
          property: "og:url",
          content: "https://www.namos.co.me" + this.$route.path
        }
      ]
    };
  },
  props: {
    id: String
  },
  created() {
    this.loadItem();
  },
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => (this.zooms = false), 500);
  },
  methods: {
    calcPopustA(donja, srednja, gornja, gornja2) {
      var a = 0.0;
      if (gornja != 0) {
        a = ((gornja - srednja) / gornja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else if (gornja2 != 0) {
        a = ((gornja2 - srednja) / gornja2) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else {
        a = ((srednja - donja) / srednja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      }
    },
    calcPopustB(donja, srednja) {
      var a = ((srednja - donja) / srednja) * 100;
      if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
      return Math.floor(a);
    },
    async loadElems() {
      if (Object.keys(this.elemList).length > 0) {
        await fetch(
          "/kaoitemlist.php?elems=" + Object.keys(this.elemList).join(",")
        ).then(response => {
          response.json().then(data => {
            data.map(item => (this.elemList[item.art_id] = item));
          });
        });
        setTimeout(() => (this.elemLoaded = true), 100);
      }
    },
    async loadItem() {
      this.itemObject = {};
      const response = await fetch("/kaoitemlist.php?id=" + this.id);
      const data = await response.json();
      this.itemObject = data[0];
      JSON.parse(this.itemObject.art_artikli).map(
        item => (this.elemList[item.sifra] = {})
      );
      this.loadElems();
    },
    changeImage(idx) {
      this.pixIndex = idx;
    }
  },

  computed: {
    sTitle() {
      if (this.itemObject.pod_naziv) {
        return (
          this.itemObject.pod_naziv +
          " " +
          this.itemObject.art_naziv +
          " - Namos Dajković Co."
        );
      }
      return null;
    },
    sDesc() {
      if (this.itemObject.art_tekst) {
        return this.itemObject.art_tekst;
      }
      return null;
    },
    sIamge() {
      return "/images/namjestaj/" + this.itemObject.art_id + "/0.jpg";
    }
  }
};
</script>
<style lang="scss" scoped>
// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {
  .popustWrapper {
    width: 34vw;
    height: 20vw;
    position: absolute;
    right: 0.7vw;
    top: 25vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .popust {
    width: 15vw;
    height: 19.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 7.8vw;
    padding: 8vw 0vw 0vw;
    box-sizing: border-box;
    letter-spacing: -0.1vw;
    color: #ee1d24;
  }

  .popust span {
    font-size: 0.6em;
  }

  .popdod {
    background-image: url("/images/popusti/dodatni1.jpg");
    background-size: cover;
  }
  .popdod2 {
    background-image: url("/images/popusti/akcija2.jpg");
    background-size: cover;
  }

  .popsniz {
    background-image: url("/images/popusti/snizenje1.jpg");
    background-size: cover;
  }
  .popfinalna {
    background-image: url("/images/popusti/finalna.jpg");
    background-size: cover;
  }
  .popeksponat {
    background-image: url("/images/popusti/eksponat.jpg");
    background-size: cover;
    color: #fff !important;
  }
  .popnovo {
    background-image: url("/images/popusti/novo.jpg");
    background-size: cover;
  }

  .elemHolder {
    margin-top: 10vw;
  }

  .elCijena {
    position: relative;
  }

  .elCijena > .popustWrapper {
    width: 34vw;
    height: 20vw;
    position: absolute;
    right: 0.7vw;
    top: 5vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .popNat {
    font-size: 0.8vw;
    font-weight: 400;
    text-align: center;
    line-height: 1.3;
    top: 0px;
    color: #555;
  }

  ::v-deep .zoom {
    cursor: crosshair;
  }
  #itemHolder {
    width: 100%;
    padding: 5vw;
    box-sizing: border-box;
  }

  #iLeft {
    position: relative;
    width: 100%;
  }
  #iRight {
    position: relative;
    width: 100%;
  }

  #picHolder {
    width: 100%;
  }

  #restPic {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0.7vw 0px;
    flex-wrap: wrap;
    width: 105%;
  }

  #katt {
    h2 {
      text-transform: uppercase;
      font-weight: 300 !important;
      font-size: 3vw;
      margin: 10vw 0px 3vw 0px;
    }
  }

  #title {
    margin-bottom: 1vw;
    h1 {
      display: inline;
      font-weight: 900;
    }
  }

  .restEl {
    width: 21vw;
    height: 12.5vw;
    background-color: #eaebec;
    border: 0.2vw solid #eaebec;
    overflow: hidden;
    margin: 13px 2vw 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 21%;
    //background-image: url('../assets/loading.gif');
    img {
      cursor: pointer;
      height: 100%;
    }
  }

  #dobavljac {
    position: relative;
    right: 0px;
    img {
      width: 30vw;
    }
  }

  .cijena {
    color: #f00;
    font-size: 9vw;
    span {
      font-size: 0.8em;
    }
  }
  .popCijena {
    display: flex;
    font-size: 4vw;
    div {
      margin: 0px 3vw 0px 0px;
      text-decoration: line-through;
    }
  }

  #spec {
    width: 90%;
    padding: 4vw;
    background-color: #ddd;
    margin-bottom: 4vw;

    #specNaslov {
      font-weight: 600;
      font-size: 5vw;
    }
  }

  #spec.nar {
    margin-bottom: 1vw;
  }

  #dim {
    width: 90%;
    padding: 4vw;
    background-color: #ddd;
    margin-bottom: 2vw;

    #dimNaslov {
      font-weight: 600;
      font-size: 5vw;
    }
  }

  .elemNaziv {
    font-size: 5vw;
  }
}

// DESKTOP =================================================================================================

@media only screen and (min-width: 601px) {
  .popustWrapper {
    width: 11vw;
    height: 5vw;
    position: absolute;
    right: 0.7vw;
    top: 7vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .popust {
    width: 5vw;
    height: 6.45vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 2.8vw;
    padding: 2.4vw 0vw 0vw;
    box-sizing: border-box;
    letter-spacing: -0.1vw;
    color: #ee1d24;
  }

  .popust span {
    font-size: 1.5vw;
  }

  .popdod {
    background-image: url("/images/popusti/dodatni1.jpg");
    background-size: cover;
  }

  .popdod2 {
    background-image: url("/images/popusti/akcija2.jpg");
    background-size: cover;
  }

  .popsniz {
    background-image: url("/images/popusti/snizenje1.jpg");
    background-size: cover;
  }
  .popfinalna {
    background-image: url("/images/popusti/finalna.jpg");
    background-size: cover;
  }
  .popeksponat {
    background-image: url("/images/popusti/eksponat.jpg");
    background-size: cover;
    color: #fff !important;
  }
  .popnovo {
    background-image: url("/images/popusti/novo.jpg");
    background-size: cover;
  }

  .elCijena {
    position: relative;
  }

  .elCijena > .popustWrapper {
    width: 11vw;
    height: 5vw;
    position: absolute;
    right: 0.7vw;
    top: 0vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .popNat {
    font-size: 0.8vw;
    font-weight: 400;
    text-align: center;
    line-height: 1.3;
    top: 0px;
    color: #555;
  }

  ::v-deep .zoom {
    cursor: crosshair;
  }
  #itemHolder {
    width: 100%;
    display: flex;
    padding: 2vw;
    justify-content: space-between;
    box-sizing: border-box;
  }

  #iLeft {
    position: relative;
    width: 46vw;
  }
  #iRight {
    position: relative;
    width: 46vw;
  }

  #picHolder {
    width: 46vw;
  }

  #restPic {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0.7vw 0px;
    flex-wrap: wrap;
    width: 105%;
  }

  #katt {
    h2 {
      text-transform: uppercase;
      font-weight: 300 !important;
      font-size: 1.2vw;
    }
  }

  #title {
    margin-bottom: 1vw;
    h1 {
      display: inline;
      font-weight: 900;
    }
  }

  .restEl {
    width: 9.55vw;
    height: 6vw;
    background-color: #eaebec;
    border: 0.2vw solid #eaebec;
    overflow: hidden;
    margin: 13px 0.4vw 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 21%;
    //background-image: url('../assets/loading.gif');
    img {
      cursor: pointer;
      height: 100%;
    }
  }

  #dobavljac {
    position: absolute;
    right: 0px;
    top: 1vw;
    img {
      width: 9vw;
    }
  }

  .cijena {
    color: #f00;
    font-size: 2vw;
    span {
      font-size: 0.8em;
    }
  }
  .popCijena {
    display: flex;
    font-size: 1.2vw;
    div {
      margin: 0px 0.2vw;
      text-decoration: line-through;
    }
  }

  #spec {
    width: 90%;
    padding: 2vw;
    background-color: #ddd;
    margin-bottom: 2vw;

    #specNaslov {
      font-weight: 600;
      font-size: 1.3vw;
    }
  }

  #spec.nar {
    margin-bottom: 1vw;
  }

  #dim {
    width: 90%;
    padding: 2vw;
    background-color: #ddd;
    margin-bottom: 2vw;

    #dimNaslov {
      font-weight: 600;
      font-size: 1.3vw;
    }
  }

  .elemNaziv {
    font-size: 1.4vw;
  }
}
</style>
